@import "src/styles/settings/settings";

.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background: rgb(34, 34, 34);
  background: linear-gradient(90deg, rgba(34,34,34,1) 0%, rgba(150,200,162,1) 52%, rgba(34,34,34,1) 100%);

  @include media(">=laptop") {
    padding: 40px 0 40px 40px;
  }


  .google-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-wrapper {
    &.simple {
      .content {
        @include media(">=tablet") {
          font-size: 24px;
        }
      }
    }

    .content-border {
      border: 2px solid #345d3d;
      border-radius: 10px;
      padding: 140px 160px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background-color: #222222;
    }

    @include media(">=laptop") {
      flex-direction: row;
      justify-content: space-between;

      &.simple {
        align-items: center;

        .content {
          font-size: 22px;
          margin-bottom: 60px;
          color: #EEEEEE;
          text-align: center;
        }
      }
    }
  }
}


.btn-title {
  font-size: 30px;
  color: aliceblue;
}

.google-sign-in-btn {
  background-color: #131314;
  padding: 10px 40px;
  border: 1px solid #747775;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);

  &:hover {
    @include white-shadow(.4);
    transform: scale(1.01);
    border-color: color(white);
  }
}

.google-logo {
  height: 40px;
  width: 40px;
  margin-right: 20px;
}