@import "src/styles/settings/settings";

.accordion-wrapper {
  :global .MuiPaper-root {
    padding: 0 20px;
    width: 100%;
    background-color: color(secondary);
  }
}

.accordion {
  .pages-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-bottom: 10px;

    .page {
      @include reset-button;

      font-size: 16px;
      width: 100%;
      color: color(white);
      padding: 8px;
      background-color: color(grey);

      &:hover {
        background-color: color(main, .2);
      }
    }
  }
}