@import "src/styles/settings/settings";

.wrapper {
  position: relative;

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0;

    .button {
      @include reset-button;
      @include transition(background-color);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;

      &:hover {
        background-color: color(secondary, .1);
      }

      .icon {
        @include icon-size(20px);
      }
    }
  }
}

.input {
  :global .MuiInputBase-input {
    padding-right: 40px;
  }
}