@import "src/styles/settings/settings";

.agent-checkpoints-display {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.agent-checkpoints-wrap {
  display: flex;
  flex-direction: column;
}

.checkpoint-column {
  min-width: 250px;
  width: 100%;
  border-radius: 8px;

  &_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid color(white, 0.5);
    padding: 10px 0;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid color(white);
    }
    &:hover .total-icon svg {
      transform: scale(1.2);
    }
    &.no-border {
      border: none;
    }

    .total-icon {
      transform: rotate(-90deg);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease;
      & svg {
        transition: transform 0.3s ease;
      }

      &_open {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }
    }

    h3 {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      grid-column: span 3;
    }

    .column-info {
      text-align: center;
      display: flex;
      grid-column: span 3;

      .last-played,
      .total-play-time {
        font-size: 14px;
        margin: 5px 0;
      }
    }

    .column-checkpoints_total {
      grid-column: span 1;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .initiator-name {
    font-style: italic;
  }
}

.checkpoint-card {
  background-color: #222222;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 4px;
  margin-bottom: 10px;

  .play-duration {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .played-at {
    font-size: 12px;
  }
}
