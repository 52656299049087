@import "src/styles/settings/settings";

.anchor {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px;
  border-radius: 4px;
  &:hover {
    border: 1px solid color(white);
  }
}

.button {
  @include reset-button;

  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: color(white);
  outline: none;
  text-decoration: none;
  padding: 10px 0 8px;
  border-bottom: 2px solid transparent;
  gap: 6px;

  .icon {
    @include transition(transform, .1s);
    @include icon-size(12px)
  }

  &.active {
    cursor: pointer;

    .icon {
      transform: rotate(-90deg);
    }
  }
}

.menu {
  .customMenuPaper {
    background: color(main);
    border: 1px solid color(white);
  }
  .item {
    color: color(white);
    &.info {
      pointer-events: none;
      cursor: default;
      color: color(white);
    }
  }
  .hasActive {
    &:hover {
      color: color(greyWhite);
    }
  }

  :global .MuiPaper-root {
    overflow: visible;
    filter: drop-shadow(0px 2px 8px color(black, 0.32));
    margin-top: 10px;

    :global .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -6px;
      right: 14px;
      width: 10px;
      height: 10px;
      background: color(main);
      border-top: 1px solid color(white);
      border-left: 1px solid color(white);
      transform: translateX(-50%) rotate(45deg);
      z-index: 0;
    }
  }
}
