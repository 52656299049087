@import "src/styles/settings/settings";

.agent {
  @include transition(transform);

  position: relative;
  border-radius: 5px;
  min-width: 170px;

  &-image {
    @include transition(border-color);
    display: flex;
    justify-content: center;
    border-radius: 5px;

    &.selected,
    &.hovered,
    &:hover {
      border-color: color(muiBlue);
    }

    img {
      height: 80px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .creator-name {
    margin-top: 10px;
    text-align: center;
  }
  .create-time {
    text-align: center;
  }

  &:hover {
    transform: scale(1.05);
  }

  .btns-wrapper {
    position: absolute;
    right: 0px;
    bottom: 8px;

    display: flex;
    gap: 8px;
  }
  .avatar {
    &,
    canvas {
      pointer-events: none;
    }
  }
  .default-image {
    max-width: 154px;
  }
}

.modal-subtitle {
  text-align: center;
  color: color(main);
  font-weight: bold;
}
