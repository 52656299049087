@import "src/styles/settings/settings";

.nav-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media("<tablet") {
    flex-direction: row-reverse;
  }

  .nav-links-wrapper {
    display: flex;
    gap: 16px;

    margin-left: 32px;

    @include media("<tablet") {
      display: none;
    }

    .nav-link {
      color: color(white);
      outline: none;
      text-decoration: none;
      padding: 10px 0 8px;
      border-bottom: 2px solid transparent;

      &:hover {
        color: color(greyWhite);
      }

      &.active {
        cursor: default;
        pointer-events: none;
        border-bottom-color: color(white);
      }
    }
  }

  .profile-section {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.dropdown-row {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  width: 100%;
  margin: 0 3px;

  &.center {
    justify-content: center;
  }

  .user-name {
    font-weight: 600;
    font-size: 24px;
    word-break: break-word;
    text-align: center;
  }

  .text {
    @include ellipsis-text;
  }

  .icon {
    @include icon-size(18px);
    margin: 0;
  }
  :global .MuiAvatar-root {
    @include icon-size(80px);
    margin-left: 0;
    margin-right: 0;
  }
}
