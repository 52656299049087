@import "src/styles/settings/settings";


.page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }
}
.info-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  .info-search {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 14px;
    min-height: 56px;
    & .info-search-icon {
      top: 14px;
      right: 9px;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid color(white);
      }
    }
  }

  .info-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .create-button-wrapper {
      cursor: pointer;
      border: 1px solid transparent;
      padding: 8px;
      border-radius: 4px;
      &:hover {
        border: 1px solid color(white);;
      }
    }
  }

  .instances {
    position: relative;
    margin-top: 32px;
    width: 100%;
  }

  &.game-info-group {
    .game-group {
      margin-top: 0;
    }
  }
  .info-group-row {
    margin-top: 12px;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;

    p {
      width: 120px;
    }

    .filters {
      display: flex;
      flex-direction: column;

      .filters-agent-status {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 24px;
        &_select {
          width: 200px;
        }
      }

      .filters-date-picker {
        width: 100%;
        display: flex;
        justify-content: end;
        align-self: start;
      }
    }
  }
}

.data-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 30px;
  background: color('main');
  box-shadow: 1px 1px 10px #141414,
  -1px -1px 10px #525252;
  margin-bottom: 24px;
  & h2 {
    margin-bottom: 24px;
  }
}

.agents-wrapper {
  width: 100%;
  margin-top: 24px;
  gap: 24px;
}

.agent-avatar, .default-avatar {
  margin-right: 8px;
  height: 30px;
  border-radius: 5%
}

.modal-subtitle {
  color: color(main);
}

.agents-name-sell {
  display: flex;
  align-items: center;
}

.action-item:not(:last-child) {
  margin-bottom: 4px;
}
