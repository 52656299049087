@import "src/styles/settings/settings";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }

  .create-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .create-button {
      cursor: pointer;
      border: 1px solid transparent;
      padding: 8px;
      border-radius: 4px;

      &:hover {
        border: 1px solid color(white);;
      }
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .modal-subtitle {
    text-align: center;
    color: color(main);
    font-weight: bold;
  }
}
