@import "src/styles/settings/settings";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;

  & > h2, p {
    text-align: center;
  }
}