@import "src/styles/settings/settings";

.wrapper {
  width: 100%;
}

.data-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 30px;
  background: color('main');
  box-shadow: 1px 1px 10px #141414,
  -1px -1px 10px #525252;
  margin-bottom: 24px;
  & h2 {
    margin-bottom: 24px;
  }

  .user-profile-role {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}

.section {
  border-radius: 30px;
  background: color('main');
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  .profile-container {
    width: 100%;

    @include media("<=tablet") {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .title {
    text-align: center;

    @include media(">=tablet") {
      text-align: left;
    }
  }

  .game-group, .agent-group {
    width: 100%;
    overflow-x: auto;
    margin-top: 24px;
    left: 0;
    padding-bottom: 20px;
  }

  .profile {
    margin-top: 24px;

  }

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    margin-left: auto;

    p {
      width: 120px;
    }

    div {
      width: 200px;
    }
  }

  .filters-date-picker {
    width: 100%;
    margin-top: 24px;
  }
}

.modal-desc {
  text-align: center;
  color: color(main);
}

.minutes-input {
  margin-top: 24px;
}

.user-data-container:not(:first-child) {
  margin-bottom: 24px;
}


.agents-name-sell {
  display: flex;
  align-items: center;
}

.agent-avatar, .default-avatar {
  margin-right: 8px;
  height: 30px;
  border-radius: 5%
}

.table-wrapper {
  padding-bottom: 24px;
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.action-item:not(:last-child) {
  margin-bottom: 4px;
}
