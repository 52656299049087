@import "src/styles/settings/settings";

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: calc(100% - 40px);
  padding: 20px;

  overflow: auto;

  .buttons-section {
    display: flex;
    justify-content: center;
    gap: 12px;

    .button {
      position: relative;
      &.loading {
        span {
          visibility: hidden;
        }
      }
      .btn-loader {
        :global .icon {
          @include icon-size(24px);
          color: color(white);
        }
      }
    }
  }
}