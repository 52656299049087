@import "src/styles/settings/settings";

.container {
  width: 100%;
  padding: 0 20px;

  @include media(">=tablet") {
    padding: 0;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  .go-back-btn {
    .icon {
      @include icon-size(16px);
      margin-right: 12px;
    }
  
    div {
      display: flex;
      align-items: center;
      background: #96C8A2;
      color: black;
      font-weight: bold;
      padding: 2px 6px;
      border-radius: 5px;
    }
  }
}


.text-area-container {
  margin-top: 30px;
}

.editable-text-area {
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.modal-subtitle {
  text-align: center;
  color: color(main);
  font-weight: bold;
  margin-bottom: 20px;
}
