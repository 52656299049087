@import "src/styles/settings/settings";

.wrapper {
  @include transition(transform);
  background-color: color(main);
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: center;
    width: calc(100% - 40px);

    @include media(">=tablet") {
      width: 100%;
    }

    @include media(">=tablet") {
      justify-content: flex-end;
    }

    .search-wrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .input-wrapper {
        display: block;

        z-index: 1;
        width: 100%;

        &.hidden {
          display: none;
        }
      }
    }
  }
  .search-btn {
    @include reset-button;
    @include visible;

    position: absolute;
    top: 40px;
    right: 40px;

    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;

    &.hidden {
      @include hidden;
    }

    .icon {
      @include transition(transform);
      @include icon-size(20px);
      color: color(white);
    }

    &:hover {
      .icon {
        transform: scale(1.05);
      }
    }
  }
}
