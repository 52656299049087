@import "src/styles/settings/settings";

.user-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 20px;

  .content {
    @include transition(height, 2s);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    .default-avatar {
      @include icon-size(80px);
    }

    .user-avatar {
      @include image-container(80px, 80px);
      border-radius: 50%;
    }

    .image-row {
      margin-bottom: 16px;
    }

    .user-name {
      font-weight: 600;
      font-size: 28px;
      max-width: 100%;
      width: 100%;
      word-break: break-word;
      text-align: center;
    }

    .user-email {
      margin-top: 8px;
      font-size: 18px;
      opacity: 0.8;
      white-space: normal;
      word-break: break-word;

      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }

  .content-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  .content-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .last-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-row {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
  }
}
