@import "src/styles/settings/settings";

@mixin _componentTheme($color) {
  &.text-field {
    width: 100%;

    :global .MuiFormControl-root, :global .MuiInputBase-root, :global .MuiTextField-root {
      width: 100%;
    }

    :global .MuiFormLabel-root, :global .MuiInputBase-root, :global .MuiFormLabel-asterisk, :global .MuiSvgIcon-root {
      color: $color;
    }

    :global .MuiInputBase-root {
      fieldset {
        border-color: rgba($color, .8);
      }
    }

    &.no-value {
      :global .MuiFormLabel-root, :global .MuiFormLabel-asterisk {
        color: color(lightGrey);

        &.Mui-focused {
          color: $color;
        }
      }
      &:focus-within {
        :global .MuiFormLabel-root, :global .MuiFormLabel-asterisk {
          color: $color;
        }
      }
    }

    &.changed {
      :global .MuiInputBase-root {
        background-color: color(white, .1);
      }
    }

    &.error {
      :global .MuiInputBase-root {
        fieldset {
          border-color: color(darkRed);
        }
      }
    }


    &.disabled {
      :global .MuiFormLabel-root, :global .MuiInputBase-root, :global .MuiFormLabel-asterisk {
        color: rgba($color, .4);
      }
      :global .Mui-disabled {
        -webkit-text-fill-color: rgba($color, .4);
      }
      :global .MuiInputBase-root {
        fieldset {
          border-color: rgba($color, .2);
        }
      }
    }
  }
}

.white {
  @include _componentTheme(color(white));
}

.black {
  @include _componentTheme(color(black));
}
