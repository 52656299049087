@import "src/styles/settings/settings";

.api-keys-list {
  position: relative;
  background-color: #333333;
  border-radius: 4px;

  .api-key-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;

    @include media(">=tablet") {
      gap: 16px;
      padding: 8px 24px;
    }

    &:nth-of-type(even) {
      background-color: rgba(17, 17, 17, 0.6);
    }

    .api-key-name {
      width: 35%;

      @include media(">=tablet") {
        width: 30%;
      }
    }
    .api-key {
      width: 45%;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media(">=tablet") {
        width: 35%;
      }
    }
    .api-key-created-at {
      display: none;

      @include media(">=tablet") {
        display: block;
        width: 25%;
      }
    }
    .api-key-created-at {
      text-align: center;
    }

    .api-key-icons {
      width: 20%;
      display: flex;
      gap: 4px;
      justify-content: end;

      @include media(">=tablet") {
        gap: 16px;
        width: 10%;
      }

      .action-icon {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
  }
}

.modal-subtitle {
  text-align: center;
  color: color(main);
  font-weight: bold;
}
