@import "src/styles/settings/settings";

.loader-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color(black, .3);

  padding: 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99999;
}

.absolute {
  width: 100%;
  height: 100%;
  position: absolute;

  margin: 0;
  display: flex;
  align-items: center;

  .icon {
    z-index: 100;
  }
}

.backdrop {
  background-color: color(main, .4);
  z-index: 90;
}

.icon {
  color: color(coral);
  padding: 20px 0;
  @include icon-size(30px);

  &.full-page {
    @include icon-size(80px);
  }
}
