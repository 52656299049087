@import "src/styles/settings/settings";

.page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }
}

.title-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.chat-list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
}
