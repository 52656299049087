
.label-input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
  }
}

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  &_pagination {
    margin-top: 24px;
  }
}
