@import "src/styles/settings/settings";

.add-characteristic {
  .add-characteristic-content {
    padding: 0 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .add-btn {
      display: flex;
      justify-content: center;
    }
  }
}
