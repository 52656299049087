@import "src/styles/settings/settings";

.wrapper {
  width: 100%;

  .title {
    text-align: center;
  }

  .table-wrapper {
    margin-top: 32px;
  }
}