@import "src/styles/settings/settings";

button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  border: none;
  background: transparent;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  text-align: center;
  text-decoration: none;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageButton {
  color: color(greyWhite);
  padding: 4px 12px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid transparent;

  &:hover {
    color: color(white);
    font-weight: bold;
  }

  &.active {
    border: 1px solid color(greyWhite);
    color: color(white);
    font-weight: bold;
  }
}

.buttonArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: color(greyWhite);
  padding: 4px 12px;
  margin: 0 4px;
  cursor: pointer;

  &:hover {
    color: color(white);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
