@import "src/styles/settings/settings";

.section {
  border-radius: 30px;
  background: color('main');
  padding: 30px;

  & h3 {
    margin-bottom: 24px;
  }

  .messagesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 24px;

    .message {
      font-size: 16px;
      font-weight: 600;
      color: color(greyWhite);
      margin-bottom: 2px;
    }
    .date {
      display: flex;
      font-size: 12px;
      color: color(lightGrey);
    }
  }


}