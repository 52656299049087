@import "src/styles/settings/settings";

.accordion-wrapper {
  width: 100%;
  :global .MuiPaper-root {
    background-color: transparent;
    color: color(white);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }
}

.accordion {
  &.reverse {
    .summary {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:before {
    display: none
  }

  :global .MuiSvgIcon-root {
    font-size: 16px;
    color: color(white);
  }
  :global .MuiAccordionDetails-root {
    padding: 0;
  }
}

.summary {
  padding: 0;
  gap: 20px;

  &.expanded {
    & > div:last-child {
      transform: rotate(90deg);
    }
  }

  .summary-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
