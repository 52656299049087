// GameInstanceDetail styles
.gameInstanceDetail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #1a1a1a;
    color: #e0e0e0;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #ffffff;
  }
  
  .infoRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .infoColumn {
    flex: 1;
    min-width: 300px;
    background-color: #2a2a2a;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .section {
    margin-bottom: 3rem;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  // Game Instance Info styles
  .gameInstanceInfo {
    background-color: #333333;
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
  }
  
  .instanceId {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #ffffff;
  }
  
  .instanceTime {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    color: #cccccc;
  }
  
  .playedTime {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #ffffff;
  }
  
  .time {
    font-weight: bold;
    color: #4CAF50;
  }
  
  .ongoingBadge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #4CAF50;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
  }
  
  // Agent and Checkpoint list styles
  .agentList,
  .checkpointList {
    list-style-type: none;
    padding: 0;
  }
  
  .agentItem,
  .checkpointItem {
    margin-bottom: 1rem;
  }
  
  // Checkpoint styles
  .checkpointWrapper {
    border: 1px solid #3a3a3a;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #2a2a2a;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
  
  .checkpointTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .checkpointTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableHeader {
    text-align: left;
    padding: 0.75rem;
    border: 1px solid #3a3a3a;
    background-color: #333333;
    font-weight: 600;
    color: #ffffff;
  }
  
  .updatedStateHeader {
    border-left: 2px solid #4a4a4a;
  }
  
  .tableCell {
    padding: 0.75rem;
    border: 1px solid #3a3a3a;
    vertical-align: top;
  }
  
  .updatedStateCell {
    border-left: 2px solid #4a4a4a;
  }
  
  .stateContent {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 0.875rem;
    overflow: auto;
    max-height: 24rem;
    background-color: #333333;
    color: #e0e0e0;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  .icon {
    cursor: pointer;
    margin-right: auto;
  }