@import "src/styles/settings/settings";

.page {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #bebebe;
  @include media(">=laptop") {
    padding: 40px 0 40px 40px;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    .title {
      text-align: center;
      font-size: 42px;
      text-transform: capitalize;
      color: #2c2c2c;
    }

    &.simple {
      .content {
        @include media(">=tablet") {
          font-size: 24px;
        }
      }
    }

    @include media(">=laptop") {
      flex-direction: column;
      justify-content: space-between;

      &.simple {
        align-items: center;

        .content {
          font-size: 32px;
          padding-bottom: 120px;
        }
      }
    }

    .content {
      padding: 0 20px;
      width: 100%;
      max-width: 100%;

      @include media(">=laptop") {
        padding: 0;
        width: 40%;
        max-width: 40%;
        margin-top: 40px;
      }

      .login-button {
        background: #ffffff;
        color: #757575;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        text-transform: none;
        font-size: 14px;
        font-weight: bold;
        justify-content: start;
        &:hover {
          background: #f5f5f5;
          color: #757575;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
      }

      .google-logo {
        width: 20px;
        height: 20px;
        margin-right: 0.5em;
      }
    }
  }
}
