@import "../../../styles/settings/settings";


.side-panel-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.side-panel-overlay.show {
  opacity: 1;
}

.side-panel-content {
  position: fixed;
  top: 0;
  left: -45%;
  bottom: 0;
  width: 45%;
  background: color(secondary);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  overflow-y: auto;
  padding: 16px;
  transition: left 0.3s ease;
  border-radius: 0 8px 8px 0;
}

.side-panel-content.show {
  left: 0;
}

.no-scroll {
  overflow: hidden;
}
