@import "src/styles/settings/settings";

.page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;

    @include media(">=laptop") {
      flex-direction: row;
      justify-content: space-between;
    }

    .top-section {
      width: 100%;
      max-width: 100%;

      @include media(">=laptop") {
        width: 60%;
        max-width: 60%;
      }
    }

    .basic-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @include media(">=laptop") {
        width: 40%;
      }

      .title {
        text-align: center;
      }

      .info-wrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .info {

          p {
            text-align: center;
            font-size: 18px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}
