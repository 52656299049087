@import "src/styles/settings/settings";

.instances-wrapper {
  width: 100%;

  @include media('>=laptop') {
    max-width: 100%;
  }

  .instances {
    display: flex;
    width: 100%;

    .instance {
      min-width: 260px;

      @include media('>=tablet') {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

.games-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &_title {
    display: flex;
  }
}

.filters {
  display: flex;
  gap: 15px;
  width: 100%;
}

.basic-stats {
  width: 100%;
  h3 {
    margin-bottom: 24px;
  }
  & p:not(:last-child) {
    margin-bottom: 8px;
  }
  & p span {
    font-weight: 700;
  }
}

.histogram-wrapper {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
}

.running-games-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  border-bottom: none;
  padding-bottom: 24px;
  h3 {
    margin-bottom: 24px;
  }
}

.has-border {
  border-bottom: 0.5px solid color(greyWhite);
}

.completed-game-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
}

.modal-subtitle {
  color: color(main);
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.action-item:not(:last-child) {
  margin-bottom: 4px;
}