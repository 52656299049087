@import "src/styles/settings/settings";

.icon-button {
  @include reset-button;
  @include white-shadow;
  @include transition(transform);

  cursor: pointer;
  border-radius: 50%;
  border: 1px solid color(white, .6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    @include icon-size(14px);
    width: 100%;
    height: 100%;
    color: color(white);
  }
}

.default {
  background-color: color(black, .8);
}

.red {
  background-color: color(darkRed, .8);
}