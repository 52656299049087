@import "src/styles/settings/settings";

.page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }
  
  .invitation-title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      @include reset-button;
      @include transition(transform);
      display: flex;
      align-items: center;

      position: relative;
      top: 2px;

      width: 24px;
      height: 24px;

      .icon {
        @include transition(color);
        @include icon-size(24px);
        color: color(darkGreen);
      }
      &:hover {
        transform: scale(1.1);

        .icon {
          color: color(green);
        }
      }
    }
  }
}

.info-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  .info-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .instances {
    position: relative;
    margin-top: 32px;
    width: 100%;
  }

  &.game-info-group {
    .game-group {
      margin-top: 0;
    }
  }
  .info-group-row {
    margin-top: 12px;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    gap: 24px;
    margin-left: auto;

    p {
      width: 120px;
    }
    div {
      width: 200px;
    }
  }
}

.nothing-to-show {
  margin-top: 24px;
  text-align: center;

  @include media(">=tablet") {
    text-align: left;
  }
}

.remaining-play-minutes {
  text-align: center;
  color: color(main);
}

.checkout-btn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.minutes-input {
  margin-top: 24px;
}

.create-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .create-button {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 8px;
    border-radius: 4px;

    &:hover {
      border: 1px solid color(white);;
    }
  }
}

.title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}