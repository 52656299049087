@import "src/styles/settings/settings";

.page-wrapper {
  position: relative;
  width: 100%;

  @include media(">=tablet") {
    padding: 0 40px;
  }

  .container {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.simple {
    padding: 0;
    margin: 0;

    .container {
      display: block;
      padding: 0;
      margin: 0;
    }
  }

  .feedback-wrapper {
    position: fixed;
    right: 20px;
    bottom: 20px;

    .btns-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .feedback-btn {
        @include reset-button;
        @include transition(transform);

        .icon {
          color: color(white);
        }

        &:hover {
          transform: scale(1.1);
        }
      }
      .send-btn {
        .icon {
          color: color(darkGreen);
        }
        &:hover {
          .icon {
            color: color(green);
          }
        }
      }
    }

    .feedback-input-wrapper {
      position: absolute;
      bottom: -8px;
      right: -10px;

      width: 300px;
      padding: 10px 60px 10px 10px;
      border-radius: 6px;
      background-color: color(secondary);
      z-index: -1;

      @include media(">=lphone") {
        width: 400px;
      }
    }
  }

}