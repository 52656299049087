@import "src/styles/settings/settings";

.modal {
  @include white-shadow(.2);
  border-radius: 8px;

  padding: 0;
  margin: 0;
  background-color: color(white);
  width: 100%;

  &-wrapper {
    padding: 10px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    min-width: 0;
    width: calc(100% - 20px);
    margin: auto;
    outline: none;

    &.sm {
      max-width: 400px;
    }
    &.md {
      max-width: 600px;
    }
    &.lg {
      max-width: 800px;
    }
  }

  .description-wrapper {
    width: 100%;
    .description {
      text-align: center;
      color: color(main, .6);
    }
  }

  .header {
    padding: 15px 20px;
    background-color: color(secondary);
    border-radius: 8px 8px 0 0;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: color(white);
    }
  }
}