@import "src/styles/settings/settings";

.field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;

  &.updatable {
    @include transition(transform);
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  &.changed {
    background-color: color(white, .1);
    box-shadow: 0 0 5px 2px color(white, .1);
  }
}

input {
  display: none;
}

.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px dashed #ccc;
  min-height: 150px;
  font-size: 16px;
  color: #666;
}

.upload-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-field-title {
  margin-bottom: 8px;
  font-weight: bold;
}