@import "src/styles/settings/settings";

.page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include media(">=tablet") {
    padding: 64px;
  }
}
.info-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=tablet") {
    align-items: flex-start;
  }

  .info-search {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 14px;
    min-height: 56px;
    & .info-search-icon {
      top: 14px;
      right: 9px;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid color(white);
      }
    }
  }

  .info-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .instances {
    position: relative;
    margin-top: 32px;
    width: 100%;
  }

  &.game-info-group {
    .game-group {
      margin-top: 0;
    }
  }
  .info-group-row {
    margin-top: 12px;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    gap: 24px;
    margin-left: auto;

    p {
      width: 120px;
    }
    div {
      width: 200px;
    }
  }
}

.nothing-to-show {
  margin-top: 24px;
  text-align: center;

  @include media(">=tablet") {
    text-align: left;
  }
}

.remaining-play-minutes {
  text-align: center;
  color: color(main);
}

.checkout-btn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.minutes-input {
  margin-top: 24px;
}

.select-users {
  margin-top: 20px;
}