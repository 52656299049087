@import "src/styles/settings/settings";

.container {
  width: 100%;
  padding: 0 20px;

  @include media(">=tablet") {
    padding: 0;
  }
}
