@import "src/styles/settings/settings";

.navbar {
  .content {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    min-height: 52px;
    border-bottom: 0.5px solid color(white, .5);
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
  }
}

.navbar-logo {
  position: relative;
  gap: 8px;
  display: flex;
  align-items: center;

  &, h2 {
    margin: 0;
    min-width: max-content;
    color: color(white);
    outline: none;
    text-decoration: none;
  }
  h2 {
    padding: 0 0 3px;
  }
  img {
    height: 40px;
  }
}

.link-active {
  cursor: default;
  pointer-events: none;
}