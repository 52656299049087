@import "src/styles/settings/settings";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.title-section {
  display: flex;
  justify-content: space-between;

  .title-icons-wrapper {
    display: flex;
    gap: 32px;

    @include media("<tablet") {
      gap: 4px;
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 16px;

      @include media("<tablet") {
        gap: 4px;
      }
    }
  }
}

.text-area-container {
  margin-top: 30px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 32px;

  :global .hints-wrapper {
    max-width: 100%;
  }

  .text-fields-section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 16px;
    width: 100%;
  
    .button-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .agent-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("<tablet") {
      width: 100%;
    }

    .agent-image {
      width: 240px;
    }
  }
}

.agent-images {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.image-section {
  @include media('>=tablet') {
    width: 65%;
  }
}

.characteristic-row, .memory-row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.characteristic-row {
  position: relative;

  .remove-btn {
    position: absolute;
    right: -35px;
  }
}
