@import "src/styles/settings/settings";

.date-pickers-wrapper {
  display: flex;
  justify-content: end;
  align-self: start;

  .date-pickers {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-left: auto;
  }
}